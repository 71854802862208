// Import Section Start
// Builder projects shown on the carosuel format with based on city
import * as React from "react";
import firebaseApp from "firebase/app";
import { useNavigate, Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import db from "./firebaseConfig";
import { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { CardMedia } from "@mui/material";
import appartment_logo from "../images/appartment.svg";
import plot_logo from "../images/plot.svg";
import vila_logo from "../images/vila.svg";
import { ReactSVG } from "react-svg";
import "./css/home-styles.css";
import "./css/update-style.css";
import locationmarker from "../images/locationmarker.svg";
import Footer from "./Footer";
import Typewriter from "typewriter-effect";
import successpopup from "./images/sucesspopup2.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "react-bootstrap";
import firebase from "firebase";
// import Accordion from 'react-bootstrap/Accordion';
import $ from "jquery";
// import section End

var storage = firebase.storage();
// Mention Carosuel Breakpoints
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

// Main Function start
function About() {
  // states declaration
  const [info, setInfo] = useState([]);
  const [searchInfo, setSearchInfo] = useState([]);
  const navigate_search = useNavigate();
  const [buildername, setBuilderName] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [settextinputbrochure, setTextInputBrochure] = useState(false);
  const [counter, setcounter] = useState(60);
  const [resendtime, setResendTime] = useState(true);
  const [resendtimeotp, setResendTimeotp] = useState(false);
  const [imagedata, setImagedata] = useState("");
  const [successModalShow, setSuccessModalShow] = useState(false);

  useEffect(async () => {
    // var randomIndex = Math.floor(Math.random() * imagedataupadted.length);

    // setImagedata(imagedataupadted[randomIndex]);
    await firebaseApp
      .auth()
      .signInAnonymously()
      .then(() => {
        Fetchdata();
      })
      .catch((error) => {
        if (error.code === "auth/operation-not-allowed") {
        }
      });

    setTimeout(() => {
      setModalShow(true);
    }, 15000);
  }, []);

  // Home page feedback form validate function
  async function home_feedback() {
    if (document.getElementById("brochure_name_data").value === "") {
      document.getElementById("brochure_name").style.display = "block";
      document.getElementById("brochure_mail").style.display = "none";
      document.getElementById("brochure_mobile_number").style.display = "none";
    } else if (
      document.getElementById("brochure_mobile_number_data").value === ""
    ) {
      document.getElementById("brochure_mobile_number").style.display = "block";
      document.getElementById("brochure_mail").style.display = "none";
      document.getElementById("brochure_name").style.display = "none";
    } else if (document.getElementById("brochure_mail_data").value === "") {
      document.getElementById("brochure_mail").style.display = "block";
      document.getElementById("brochure_mobile_number").style.display = "none";
      document.getElementById("brochure_name").style.display = "none";
    } else {
      // feedback_brochure_store();
      // brochure_mail_send();
      // onSignInSubmitbrochure();
      setTextInputBrochure(true);
      var timeleft = 60;
      var downloadTimer = setInterval(function () {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          setResendTime(false);
        }
        setcounter(timeleft);
        timeleft -= 1;
      }, 1000);
    }
  }

  // Resend otp function start
  function Resendotp() {
    setResendTime(true);
    setTextInputBrochure(true);
    onSignInSubmitotp();

    var timeleft = 60;
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        setResendTime(false);
      }
      // document.getElementById("progressBar").value = 10 - timeleft;
      // timeleft -= 1;
      setcounter(timeleft);
      timeleft -= 1;
    }, 1000);
  }
  // Resend otp function End

  function configureCaptchabrochure() {
    window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmitbrochure();
          window.recaptchaWidgetId = response;
        },
        "expired-callback": (check) => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
        defaultCountry: "IN",
      }
    );
  }

  function configureCaptchaotp() {
    window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      resendtimeotp
        ? "recaptcha-container-resend"
        : "recaptcha-container-resends",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmitotp();
          window.recaptchaWidgetId = response;
        },
        "expired-callback": (check) => {},
        defaultCountry: "IN",
      }
    );
  }

  async function onSignInSubmitbrochure() {
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;
    await configureCaptchabrochure();
    const phoneNumber = "+91" + number_get;
    const appVerifier = window.recaptchaVerifier;
    firebaseApp
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {});
  }
  async function onSignInSubmitotp() {
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;

    await configureCaptchaotp();

    const phoneNumber = "+91" + number_get;
    const appVerifier = window.recaptchaVerifier;
    firebaseApp
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;

        // ...
      })
      .catch((error) => {});
  }

  async function home_onSubmitOTP() {
    // e.preventDefault()
    var number_get = document.getElementById("brochure_otp_data").value;

    const code = number_get;
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        // User signed in successfully.
        const user = result.user;
        var status = "success";
        // await send_feedback_msg();
        Otp_verify_check(status);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        document.getElementById("brochure_otp").style.display = "block";
      });
  }
  async function Otp_verify_check(status) {
    document.getElementById("brochure_otp").style.display = "none";
    setTextInputBrochure(false);
    try {
      await brochure_mail_send();
    } catch (error) {}

    await feedback_brochure_store();
    await setTextInputBrochure(false);
    await setModalShow(false);
  }
  async function feedback_brochure_store() {
    console.log("store");
    var name_get = document.getElementById("brochure_name_data").value;
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;
    var mail_get = document.getElementById("brochure_mail_data").value;

    var year = new Date().getFullYear();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    var month_get = month[d.getMonth()];
    const today = d.getDate();

    const data = {
      name: name_get,
      mobile_number: number_get,
      email: mail_get,
      year: year,
      month: month_get,
      date: today,
      timestamp: d,
      type: "home-feedback",
    };

    // Add a new document in collection HomeFeedback
    await db
      .collection("project_lead")
      .doc()
      .set(data)
      .then(() => {
        setModalShow(false);
        setSuccessModalShow(true);
      });
  }
  async function brochure_mail_send() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    var name_get = document.getElementById("brochure_name_data").value;
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;
    var mail_get = document.getElementById("brochure_mail_data").value;
    // var address_get =
    //   name + "," + city_data + "-" + zone_data + "," + city_data;
    var request_sender = "Feedback";
    var settings = {
      async: true,
      crossDomain: true,
      url: `https://www.fast2sms.com/dev/bulkV2?authorization=3BEbN1cOt9RMzkhd0leHCgLnPJ4IqD8YsuAF7fKSywxrm5GWajAMatnTQv6cFmeyZO04I5DBjfXgk3LK&sender_id=TXTIND&
  message=Lead alert cgch.in. Name:${name_get},Number:${number_get},
      project: ${"home"},
        route: "v3"&route=v3&numbers=9677051111`,
      method: "GET",
    };

    $.ajax(settings).done(function (response) {
      console.log(response);
    });
    fetch(
      `https://us-central1-chennaigatedcommunity-ed001.cloudfunctions.net/apiSocialShare/feedback-mail-send/${
        name_get + " " + dateTime
      }/${name_get}/${number_get}/${mail_get}/${request_sender}`
    ).then(() => {
      feedback_brochure_store();
    });
  }
  async function CloseModalBrochure() {
    await setModalShow(false);
    if (settextinputbrochure === true) {
      setTimeout(function refreshPage() {
        window.location.reload();
      }, 1000);
    }
    setTextInputBrochure(false);
  }

  // Data Fetching function start
  const Fetchdata = async () => {
    var data_array = [];

    let collectionRef = db.collection("Project_Details");
    const documentSnapshotArray = await collectionRef.get();
    const records = documentSnapshotArray.docs;
    const index = documentSnapshotArray.size;
    let result = "";
    var randomDocId = Math.floor(Math.random() * index);

    const docRef = records[randomDocId].ref;

    result = records[randomDocId].data();
    setImagedata(result.project_img);

    db.collection("Project_Details")
      .orderBy("timestamp", "desc")
      .get()
      .then((querySnapshot) => {
        // Loop get the data fetching here
        querySnapshot.forEach((element) => {
          var data = element.data();

          data.docId = element.id;
          var project_name_get = data.project_name;
          var builder_name_get = data.builder_details.builder_name;

          setInfo((arr) => [...arr, data]);
          setSearchInfo((arr) => [...arr, project_name_get, builder_name_get]);
          var builder_name_assign = data.builder_details.builder_name;
          setBuilderName((arr) => [...arr, builder_name_assign]);
          data_array.push(data);
        });
      });
  };

  // Data Fetching function End

  // Groupby section based on city
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const groupBycity = groupBy("city");
  var split_group_by_city = groupBycity(info);
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
    var event_value = event.target.value;
    return event_value;
  };

  groupBy("builder_area");
  groupBy(buildername);
  var uniquebuildername = [...new Set(buildername)];
  // Groupby section end

  // Home Page search function start
  const Fetchsearchdata = () => {
    var searchresult;

    searchresult = document.getElementById("demo-textbox").value;

    if (searchresult === "") {
      let data1 = age.trim().split(" ");
      var trim_data = data1.join("-");

      navigate_search(
        `/search-result/${trim_data.toLowerCase()}`,

        { state: { city: age, searchData: "" } }
      );
    } else if (age === "") {
      let data1 = searchresult.trim().split(" ");
      let trim_data = data1.join("-");
      navigate_search(
        `/search-result/${trim_data.toLowerCase()}`,

        { state: { searchData: searchresult, city: "" } }
      );
    } else if (age !== "" && searchresult !== "") {
      let data1 = searchresult.trim().split(" ");
      let trim_data = data1.join("-");
      navigate_search(`/search-result/${trim_data()}`, {
        state: { searchData: searchresult, city: age },
      });
    }
  };

  // Home Page search function End

  // Auto complete Search section start
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState("");

  const handleChange_input = (e) => {
    const query = e.target.value.toLowerCase();
    setValue(query);
    if (query.length < 1) {
      document.getElementById("autocom-box").style.display = "none";
    }
    if (query.length > 1) {
      document.getElementById("autocom-box").style.display = "block";
      const filterSuggestions = searchInfo.filter(
        (suggestion) => suggestion.toLowerCase().indexOf(query) > -1
      );
      console.log(filterSuggestions);
      if (filterSuggestions.length === 0) {
        document.getElementById("autocom-box").style.display = "none";
      }
      setSuggestions(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  // if(s1 ){
  //   document.getElementById("autocom-box").style.display = "none"
  // }

  const handleClick = (e) => {
    document.getElementById("autocom-box").style.display = "none";
    setSuggestions([]);
    setValue(e.target.innerText);
    setSuggestionsActive(false);
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex - 1 === suggestions.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      setValue(suggestions[suggestionIndex]);
      setSuggestionIndex(0);
      setSuggestionsActive(false);
    }
  };

  const Suggestions = () => {
    return (
      <ul className="suggestions">
        {suggestions.map((suggestion, index) => {
          return (
            <li
              className={index === suggestionIndex ? "active" : ""}
              key={index}
              onClick={handleClick}
            >
              {suggestion}
            </li>
          );
        })}
      </ul>
    );
  };

  const navigateToLink = useNavigate();

  function staticPageClick(paths) {
    navigateToLink("/" + paths);
    window.location.reload();
  }

  // Auto complete search section end
  // Content Section start

  return (
    <div>
      <div
        className="background-img"
        style={{
          height: "415px",
          width: "100%",
          backgroundImage: `url(${imagedata})`,
        }}
      >
        <div
          className="background-overlay d-flex flex-column w-100 align-items-center justify-content-center"
          style={{
            height: "415px",
            width: "100%",
            backgroundColor: imagedata ? "#00000075" : "#fff",
          }}
        >
          <Typewriter
            className="text-white text-center"
            wrapperClassName="text-white text-center"
            options={{
              strings: ["Find a dream place to live with your Family"],
              autoStart: true,
              loop: true,
              cursor: "|",
              cursorClassName: "text-white-cursor",
            }}
          />
          <p className="text-white text-center mt-3">
            100% customer satisfaction
          </p>
          <form>
            <div className="d-flex home-banner-form">
              <select onChange={handleChange}>
                <option value="">Select Location</option>
                {Object.keys(split_group_by_city).map((cityeach) => (
                  <option>{cityeach}</option>
                ))}
              </select>
              <div className="short-line"></div>
              <input
                id="demo-textbox"
                type="text"
                placeholder="Search builder name or project"
                autoComplete="none"
                value={value}
                onChange={handleChange_input}
                onKeyDown={handleKeyDown}
              ></input>
              <button
                onClick={() => Fetchsearchdata()}
                type="Search"
                className="search-btn"
              >
                Submit
              </button>
            </div>
          </form>
          <div
            class="autocom-box"
            id="autocom-box"
            style={{
              backgroundColor: "white",
              color: "black",
              display: "none",
              width: "28%",
            }}
          >
            {suggestionsActive && <Suggestions />}
          </div>
        </div>
      </div>

      <div className="container pt-60 pb-70">
        <div className="row">
          <h1 className="text-center">Categories</h1>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-8">
            <div className="row mt-4">
              <div
                onClick={() => staticPageClick("apartment")}
                className="col-md-4 responsive-mb-15"
              >
                <div className="card text-center align-items-center pointer">
                  <img
                    src={appartment_logo}
                    width="35px"
                    height="35px"
                    className="rotate-hover"
                  />
                  <h3 className="mt-3 mb-0">Apartments</h3>
                </div>
              </div>
              <div
                onClick={() => staticPageClick("plot")}
                className="col-md-4 responsive-mb-15"
              >
                <div className="card text-center align-items-center pointer">
                  <img
                    src={plot_logo}
                    width="35px"
                    height="35px"
                    className="rotate-hover"
                  />
                  <h3 className="mt-3 mb-0">Plots</h3>
                </div>
              </div>
              <div
                onClick={() => staticPageClick("villa")}
                className="col-md-4"
              >
                <div className="card text-center align-items-center pointer">
                  <img
                    src={vila_logo}
                    width="35px"
                    height="35px"
                    className="rotate-hover"
                  />
                  <h3 className="mt-3 mb-0">Villas</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Homepage course={info} />
      </div>
      {/* frequently asked questions and answers section */}
      {/* {info.length > 0 ? 
      // <HomePageFreq />
      : null} */}
      {/* Footer join section in Home page */}
      <div>{info.length > 0 ? <Footer /> : null}</div>
      <>
        <Modal
          show={modalShow}
          onHide={() => CloseModalBrochure()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5>
                <span className="text-underline-blue">You want </span>
                <span className="text-underline">Expert Consultation</span>
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 pt-0">
            <div className="right-side-form">
              <div>
                <div className="form-group">
                  <label for="yourName">Your name</label>
                  <input
                    type="email"
                    name="from_name"
                    className="form-control"
                    id="brochure_name_data"
                    aria-describedby="emailHelp"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_name"
                    className="text-red m-0"
                  >
                    Please provide your name
                  </p>
                </div>
                <div className="form-group">
                  <label for="mobileNumber">Mobile Number</label>
                  <div id="recaptcha-container"></div>
                  <div id="recaptcha-container-resend"></div>
                  <div id="recaptcha-container-resends"></div>

                  <input
                    type="email"
                    name="reply_to"
                    className="form-control"
                    id="brochure_mobile_number_data"
                    aria-describedby="emailHelp"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_mobile_number"
                    className="text-red m-0"
                  >
                    Please provide your mobile number
                  </p>
                </div>
                <div className="form-group">
                  <label for="yourMail">Your mail</label>
                  <input
                    type="text"
                    className="form-control"
                    id="brochure_mail_data"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_mail"
                    className="text-red m-0"
                  >
                    Please provide your email
                  </p>
                </div>
                {settextinputbrochure ? (
                  <div>
                    <div className="form-group">
                      <label for="yourMail">
                        Enter the OTP {resendtime ? counter : ""}
                      </label>
                      <p className="otp-notification">
                        {" "}
                        A verification code has been sent to your phone
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        id="brochure_otp_data"
                      />
                      <p
                        style={{ display: "none" }}
                        id="brochure_otp"
                        className="text-red m-0"
                      >
                        Your otp is wrong, Please enter a correct otp
                      </p>
                    </div>
                    <button
                      onClick={() => home_onSubmitOTP()}
                      style={{ borderRadius: "100px" }}
                      type="submit"
                      className="btn btn-red"
                    >
                      verify
                    </button>
                    <div className="d-flex">
                      <p className="m-0">Didn't receive OTP?</p>
                      <button
                        onClick={async () => {
                          setResendTimeotp(!resendtimeotp);
                          Resendotp();
                        }}
                        type="submit"
                        style={{ color: "red" }}
                        disabled={resendtime ? true : false}
                      >
                        Re-send otp
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => home_feedback()}
                    style={{ borderRadius: "100px" }}
                    type="submit"
                    className="btn btn-red"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={successModalShow}
          onHide={() => false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="text-center p-5">
            <img src={successpopup} style={{ width: "65px", height: "65px" }} />
            <h4 className="text-center mt-4">Cgch homes</h4>
            <p className="text-center">
              Dear customer, Thanks for your enquiry about cgch. Our Team shall
              Reach you shortly
            </p>
            <Button
              onClick={() => setSuccessModalShow(false)}
              className="btn-popup-sucess mt-3"
            >
              Continue
            </Button>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );

  //  Content Section End
}

// Main Function End

// Frame function recall
const Homepage = ({ course }) => {
  const navigate = useNavigate();
  // groupby function start
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  // groupby function End

  // Navigate funtion start
  const goToPosts = (value) => {
    let data = value.project_name_keyword;
    // var trim_data_lower_case = trim_data.toLowerCase()
    navigate(
      `/projects/${data}`,

      { state: { id: data } }
    );
    window.location.reload();
  };
  // Navigate function End

  // city groupby section start
  const groupBycity = groupBy("city");
  var split_group_by = groupBycity(course);
  // city groupby section End

  return Object.keys(split_group_by).map((cat, index) => (
    <div className="container-fluid home-sec-bg">
      <div className="container pt-70 pb-60">
        <div className="row">
          <div className="col-md-12">
            <center>
              <h1 className="text-center mb-5">
                Projects in {Object.keys(split_group_by)[index]}
              </h1>
            </center>

            <div>
              <Carousel breakPoints={breakPoints} enableMouseSwipe={false}>
                {split_group_by[cat].map((value, index) => {
                  var httpsReference_project_logo = storage.refFromURL(
                    value.project_img
                  );
                  let alt_image_name = httpsReference_project_logo.name;

                  return (
                    <React.Fragment>
                      <CardContent
                        onClick={() => goToPosts(value)}
                        style={{ width: "100%" }}
                        className="pointer"
                      >
                        <div className="relative">
                          <div className="image-hover">
                            <CardMedia
                              component="img"
                              className="cardListImg"
                              height="90"
                              image={value.project_img}
                              alt={alt_image_name}
                            />
                          </div>
                          <div className="content-sep-top card-content">
                            <h6>{value.project_name}</h6>
                            <div className="d-flex pt-1">
                              <ReactSVG src={locationmarker} />
                              <p
                                style={{ marginBottom: "0", marginLeft: "5px" }}
                              >
                                {value.Address}, {value.city}
                              </p>
                            </div>
                          </div>
                          <div className="projStatusbtn-absolute">
                            <Button className="projStatusbtn nthbg">
                              {value.project_status}
                            </Button>
                          </div>
                        </div>
                      </CardContent>
                    </React.Fragment>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

// Frame Function End
export default About;
